var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-layout',{staticClass:"pa-3",attrs:{"column":""}},[_c('p',{staticClass:"mb-2 skeleton-title rounded"}),_c('p',{staticClass:"mb-2 skeleton-category rounded"}),_c('v-layout',{staticClass:"align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"24","color":"grey"}}),_c('p',{staticClass:"mb-0 skeleton-display-name rounded"})],1)],1):_c('v-layout',{staticClass:"pa-3",attrs:{"column":""}},[_c('v-layout',[_c('base-link',{attrs:{"to":'/app/proposal/' + _vm.proposal.id}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('p',{staticClass:"mb-0 title",class:hover ? 'primary--text cursor-pointer' : '',domProps:{"textContent":_vm._s(_vm.proposal.title)}})]}}])})],1)],1),_c('v-layout',{staticClass:"mb-3",attrs:{"wrap":""}},[_c('base-link',{attrs:{"to":'/app/proposal?kategori=' + _vm.proposal.category.name}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{staticClass:"px-1 rounded",class:hover ? 'cursor-pointer' : '',attrs:{"color":"grey lighten-3"}},[_c('p',{staticClass:"mb-0 body-2",class:hover ? 'text--primary' : 'text--secondary',domProps:{"textContent":_vm._s('#' + _vm.proposal.category.name)}})])]}}])})],1)],1),_c('p',{staticClass:"mt-n2 caption text--secondary",domProps:{"textContent":_vm._s(_vm.toDate(_vm.proposal.created_at))}}),_c('v-layout',{staticClass:"align-center"},[_c('base-avatar',{staticClass:"mr-2",attrs:{"user":_vm.gallery,"size":24}}),_c('base-link',{attrs:{"to":'/app/perusahaan/' + _vm.gallery.id}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('p',{staticClass:"mb-0 font-weight-medium",class:hover
                                ? 'text-decoration-underline text--primary cursor-pointer'
                                : 'text--secondary',domProps:{"textContent":_vm._s(_vm.gallery.profile.display_name)}})]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }

























import BaseCard from "@/components/Base/BaseCard.vue";
import BaseDialog from "@/components/Base/BaseDialog.vue";
import BaseForm from "@/components/Base/BaseForm.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import SharedGalleryListItemAddButton from "./ListItemAddButton.vue";
import SharedGalleryListItemHeader from "./ListItemHeader.vue";
import SharedGalleryListItemImages from "./ListItemImages.vue";

@Component({
    components: {
        BaseCard,
        BaseDialog,
        BaseForm,
        SharedGalleryListItemHeader,
        SharedGalleryListItemAddButton,
        SharedGalleryListItemImages,
    },
})
export default class SharedGalleryListItem extends Vue {
    @Prop() fund!: any;
    @Prop() gallery!: any;
    @Prop() index!: number;

    public showDialog: boolean = false;
    public formData: any = {};
    public forms: any[] = [
        {
            text: "Gambar 1",
            placeholder: "Pilih gambar",
            value: "image_0",
            withLabel: true,
            type: "image",
        },
        {
            text: "Gambar 2",
            placeholder: "Pilih gambar",
            value: "image_1",
            withLabel: true,
            type: "image",
        },
        {
            text: "Gambar 3",
            placeholder: "Pilih gambar",
            value: "image_2",
            withLabel: true,
            type: "image",
        },
    ];

    get galleryImage() {
        let galleryImage: any = null;
        const userId: string = this.gallery.id;

        const findUserGallery = this.fund.proposal.galleries.filter(
            (gallery: any) => {
                return gallery.user.id === userId;
            }
        );

        if (findUserGallery && findUserGallery.length)
            galleryImage = findUserGallery[0];

        return galleryImage;
    }

    showUploadDialog() {
        this.showDialog = true;
    }

    async addGallery() {
        const userId = this.$store.state.users.me.id;

        this.formData.proposalId = this.fund.proposal.id;
        this.formData.userId = userId;

        const response = await this.$store.dispatch(
            "galleries/addGallery",
            this.formData
        );

        this.$store.dispatch("galleries/getGalleryByUserId", userId);

        this.showDialog = false;
        this.formData = {};
    }
}








































































































import BaseDialog from "@/components/Base/BaseDialog.vue";
import InputImage from "@/components/Input/InputImage.vue";
import { loadFile } from "@/helpers/useLoader";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseDialog,
        InputImage,
    },
})
export default class SharedGalleryListItemImages extends Vue {
    @Prop() galleryImage!: any;

    public showDialog: boolean = false;
    public showFullImage: boolean = false;
    public selectedImageMeta: any = null;
    public selectedImagePath: string = "";

    loadFile(path: string) {
        return loadFile(path);
    }

    public images: any[] = [
        { path: "image_0_path", value: "image_0" },
        { path: "image_1_path", value: "image_1" },
        { path: "image_2_path", value: "image_2" },
    ];

    public formData: any = {};

    get form() {
        let inputMeta: any = null;

        if (this.selectedImageMeta)
            inputMeta = {
                text: "Gambar",
                placeholder: "Pilih gambar",
                value: this.selectedImageMeta.value,
            };

        return inputMeta;
    }

    showUploadDialog(imageMeta: any) {
        this.selectedImageMeta = imageMeta;
        this.showDialog = true;
    }

    selectImage(image: any) {
        this.selectedImagePath = image.path;
        this.showFullImage = true;
    }

    async updateGallery() {
        let payload: any = {
            galleryData: this.formData,
            galleryId: this.galleryImage.id,
        };

        this.formData = {};

        const response = await this.$store.dispatch(
            "galleries/updateGallery",
            payload
        );

        console.log(response);
        const userId = this.$store.state.users.me.id;
        this.$store.dispatch("galleries/getGalleryByUserId", userId);

        this.showDialog = false;
    }
}

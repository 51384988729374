




































































import BaseAvatar from "@/components/Base/BaseAvatar.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import { toDate } from "@/helpers/Formatter";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseAvatar,
        BaseLink,
    },
})
export default class SharedGalleryListItemHeader extends Vue {
    @Prop() proposal!: any;
    @Prop() gallery!: any;
    @Prop() loading!: boolean;

    toDate(date: any) {
        return toDate(date);
    }
}
